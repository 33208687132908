import { useQuery } from '@tanstack/react-query';
import useLeaguesInfo from './useLeaguesInfo';
import { fetchUserInfo } from '@/services/leagues/api';
import { useAccount } from 'wagmi';
const useLeaguesUserInfo = () => {
  const {
    leaguesInfo
  } = useLeaguesInfo();
  const {
    address
  } = useAccount();
  const {
    data: leaguesUserInfo,
    refetch: refetchLeaguesUserInfo,
    isFetched
  } = useQuery({
    queryKey: ['leagues-user-info', address, leaguesInfo],
    queryFn: () => fetchUserInfo({
      seasonIdentifier: leaguesInfo?.identifier ?? '',
      epochNumber: leaguesInfo ? leaguesInfo.activeEpochIndex + 1 : 0,
      userAddress: address ?? '0x'
    }),
    refetchInterval: 120_000,
    enabled: !!address
  });
  return {
    leaguesUserInfo,
    refetchLeaguesUserInfo,
    isFetched
  };
};
export default useLeaguesUserInfo;