import { fetchLeagueInfo } from '@/services/leagues/api';
import { useQuery } from '@tanstack/react-query';
const useLeaguesInfo = () => {
  const {
    data: leaguesInfo
  } = useQuery({
    queryKey: ['leagues-info'],
    queryFn: fetchLeagueInfo
  });
  return {
    leaguesInfo
  };
};
export default useLeaguesInfo;