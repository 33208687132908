import { Abi, Address } from 'viem';
import { create } from 'zustand';

export type Txn = {
  address: Address;
  abi: Abi;
  functionName: string;
  args: any[];
  value?: bigint;
  txSuccessCallback?: () => void;
  txFailedCallback?: () => void;
};

export type TxnWithResolve = {
  tx: Txn;
  privateKey: Address;
  feedId: string | null;
};

type State = {
  isProcessingQueue: boolean;
  executionQueue: TxnWithResolve[];
};

type Action = {
  push: (transaction: TxnWithResolve) => void;
  pop: () => any;
  processingQueue: () => void;
  finishProcessingQueue: () => void;
};
const initialState: State = {
  isProcessingQueue: false,
  executionQueue: [],
};

export const useExecutionQueue = create<State & Action>((set, get) => ({
  ...initialState,
  push: (transaction: TxnWithResolve) => {
    set((state) => ({
      ...state,
      executionQueue: [...state.executionQueue, transaction],
    }));
  },
  pop: () => {
    const { executionQueue } = get();
    const txPopped = executionQueue.shift();
    set((state) => {
      return {
        ...state,
        executionQueue,
      };
    });

    return txPopped;
  },
  processingQueue: () => {
    set((state) => {
      return {
        ...state,
        isProcessingQueue: true,
      };
    });
  },
  finishProcessingQueue: () => {
    set((state) => {
      return {
        ...state,
        isProcessingQueue: false,
      };
    });
  },
}));
