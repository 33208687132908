import { Address } from 'viem';
import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

type State = {
  privateKey: Address | null;
  publicKey: Address | null;
  pinError: boolean;
  oneCTStorage: {
    [key: string]: {
      oneCTOwner: string;
      publicKey: string;
      iv: string;
      ciphertext: string;
      base64Key: string;
      enabled: boolean;
    };
  };
};

type Action = {
  updateOneCTStore: (key: keyof State, value: any) => void;
  disableOneCT: (oneCTOwner: Address) => void;
  setEnableOneCT: (oneCTAddress: Address) => void;
  enableOneCT: ({
    oneCTOwner,
    iv,
    ciphertext,
    base64Key,
    privateKey,
    publicKey,
  }: {
    oneCTOwner: Address;
    iv: string;
    ciphertext: string;
    base64Key: string;
    privateKey: Address;
    publicKey: Address;
  }) => void;
  resetStore: () => void;
  resetKeys: () => void;
  resetLocalStorage: () => void;
};

const initialState = {
  walletList: [],
  privateKey: null,
  publicKey: null,
  pinError: false,
  oneCTStorage: {},
};

type OneCTPersist = (
  config: StateCreator<State & Action>,
  options: PersistOptions<{
    oneCTStorage: {
      oneCTOwner: string;
      iv: string;
      ciphertext: string;
      base64Key: string;
      enabled: boolean;
    };
  }>
) => StateCreator<State & Action>;

export const useOneCTStore = create<State & Action>(
  (persist as OneCTPersist)(
    (set, get) => ({
      ...initialState,
      updateOneCTStore: (key, value) => {
        set(() => ({ [key]: value }));
      },

      disableOneCT: (oneCTPublicKey: Address) => {
        const oneCTStorage = get().oneCTStorage;

        oneCTStorage[oneCTPublicKey] = {
          ...oneCTStorage[oneCTPublicKey],
          enabled: false,
        };

        set(() => ({
          publicKey: null,
          privateKey: null,
          oneCTStorage,
        }));
      },
      enableOneCT: ({
        oneCTOwner,
        iv,
        ciphertext,
        base64Key,
        privateKey,
        publicKey,
      }: {
        oneCTOwner: Address;
        iv: string;
        ciphertext: string;
        base64Key: string;
        privateKey: Address;
        publicKey: Address;
      }) => {
        const oneCTStorage = get().oneCTStorage;

        let other1CTAddress = '';
        Object.keys(oneCTStorage).forEach((key) => {
          if (oneCTStorage[key].publicKey !== publicKey) {
            other1CTAddress = oneCTStorage[key].publicKey;
          }
        });

        if (other1CTAddress) oneCTStorage[other1CTAddress].enabled = false;

        oneCTStorage[publicKey] = {
          oneCTOwner,
          iv,
          ciphertext,
          base64Key,
          publicKey,
          enabled: true,
        };

        set(() => ({
          privateKey,
          publicKey,
          pinError: false,
          oneCTStorage,
        }));
      },
      setEnableOneCT: (oneCTAddress) => {
        const oneCTStorage = get().oneCTStorage;
        oneCTStorage[oneCTAddress] = {
          ...oneCTStorage[oneCTAddress],
          enabled: true,
        };

        let other1CTAddress = '';
        Object.keys(oneCTStorage).forEach((key) => {
          if (oneCTStorage[key].publicKey !== oneCTAddress) {
            other1CTAddress = oneCTStorage[key].publicKey;
          }
        });

        if (other1CTAddress)
          oneCTStorage[other1CTAddress] = {
            ...oneCTStorage[other1CTAddress],
            enabled: false,
          };
        set({ oneCTStorage });
      },
      resetLocalStorage: () => {
        set(() => ({ oneCTStorage: {}, publicKey: null, privateKey: null }));
      },
      resetStore: () => {
        set(() => initialState);
      },
      resetKeys: () => set(() => ({ privateKey: null, publicKey: null })),
    }),
    {
      name: '_$__',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({ oneCTStorage: state.oneCTStorage }),
    }
  )
);
