import { Address } from 'viem';
import { ILeaguesInfo, ILeaguesLeaderboard, ILeaguesUserInfo } from './types';
import { League } from '@/components/leagues/types';

export const fetchLeagueInfo = async (): Promise<ILeaguesInfo> => {
  const response = await fetch('https://trading-seasons.avantisfi.com/trading-seasons/active', {
    cache: 'no-store',
    next: { revalidate: 0 },
  });

  const data = await response.json();

  return data;
};

export const fetchUserInfo = async ({
  seasonIdentifier,
  epochNumber,
  userAddress,
}: {
  seasonIdentifier: string;
  epochNumber: number;
  userAddress: Address;
}): Promise<ILeaguesUserInfo> => {
  const response = await fetch(
    `https://trading-seasons.avantisfi.com/trading-seasons/leaderboard/user/${seasonIdentifier}/${epochNumber}/${userAddress}`,
    {
      cache: 'no-store',
      next: { revalidate: 0 },
    }
  );
  const data = await response.json();

  return data;
};

export const fetchLeagueLeaderboardInfo = async ({
  seasonIdentifier,
  epochNumber,
  level,
  pageNo,
}: {
  seasonIdentifier: string;
  epochNumber: number;
  level: League;
  pageNo: number;
}): Promise<ILeaguesLeaderboard> => {
  const response = await fetch(
    `https://trading-seasons.avantisfi.com/trading-seasons/leaderboard/${seasonIdentifier}/${epochNumber}?page=${pageNo}&level=${level}`,
    {
      cache: 'no-store',
      next: { revalidate: 0 },
    }
  );
  const data = await response.json();
  return data;
};
