export function serializeError(err: any) {
  // Check if err is an object and has the expected properties
  if (typeof err === 'object' && err !== null) {
    // Try to get the message from err.cause.data.args or err.shortMessage or err.message
    const message =
      err.cause?.data?.args?.join(',') || err.shortMessage || err.message || err.errorMessage;

    // If message is a non-empty string, return it
    if (typeof message === 'string' && message.trim() !== '') {
      let normalizedMessage = message.toLowerCase();

      if (normalizedMessage.includes('no_trade')) {
        return { message: "Position doesn't exist" };
      }

      if (normalizedMessage.includes('exceeds the balance of this account')) {
        return { message: 'Insufficient gas funds' };
      }

      if (normalizedMessage.includes('insufficient funds')) {
        return { message: 'Insufficient funds' };
      }

      if (normalizedMessage.includes('insufficient gas')) {
        return { message: 'Insufficient gas funds' };
      }

      if (normalizedMessage.includes('insufficient allowance')) {
        return { message: 'Please increase your allowance' };
      }

      if (normalizedMessage == '17') {
        return { message: 'Transaction requires more gas ' };
      }

      if (normalizedMessage.includes('invalid opcode')) {
        return { message: 'Invalid opcode' };
      }

      if (normalizedMessage.includes('max_pending_orders')) {
        return { message: 'An existing order is still being executed' };
      }

      if (normalizedMessage.includes('limit_timelock')) {
        return { message: 'Please wait and try again in 1 minute!' };
      }

      return { message };
    }
  }

  // Fallback: return the full error as a string (useful for unexpected error formats)
  console.log(err, 'unhandled error');
  // return { message: 'Error: ' + JSON.stringify(err, Object.getOwnPropertyNames(err)) }
  return { message: 'An unknown error occurred: please try again later' };
}
