import { fetchUserProfile } from '@/services/trade/api';
import { useQuery } from '@tanstack/react-query';
import { Address } from 'viem';
import { useAccount } from 'wagmi';
const useUserProfile = () => {
  const {
    address
  } = useAccount();
  const {
    data: userProfileData,
    isFetched,
    refetch
  } = useQuery({
    queryKey: ['user-profile', address],
    queryFn: () => fetchUserProfile({
      address: address as Address
    }),
    enabled: !!address
  });
  return {
    userProfileData,
    isFetched,
    refetchUserProfile: refetch
  };
};
export default useUserProfile;