import { ChangeEvent, Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';
import ResponsiveDialog from '../ResponsiveDialog';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { useAccount } from 'wagmi';
import { useQuery } from '@tanstack/react-query';
import { fetchRandomImageKeys, fetchUserProfileNonce, updateUserProfile, updateUserProfileImage } from '@/services/trade/api';
import { config } from '@/blockchain/configs/wagmi';
import { signMessage } from '@wagmi/core';
import Spinner from '../Spinner';
import { serializeError } from '@/lib/transaction';
import useAppToast from '@/hooks/shared/useAppToast';
import { LEAGUES_IMAGE_PREFIX } from '@/lib/env';
import { Skeleton } from '@/components/ui/skeleton';
import useLeaguesUserInfo from '@/hooks/leagues/useLeagueUserInfo';
import useUserProfile from '@/hooks/leagues/useUserProfile';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
const EditProfileImage = ({
  openModal,
  setOpenModal,
  onSuccess = () => {}
}: {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  onSuccess?: () => void;
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const {
    address
  } = useAccount();
  const {
    failed,
    success
  } = useAppToast();
  const {
    refetchLeaguesUserInfo
  } = useLeaguesUserInfo();
  const {
    userProfileData,
    refetchUserProfile
  } = useUserProfile();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [checked, setChecked] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [randomImgIdx, setRandomImgIdx] = useState(0);
  const {
    data: randomImages,
    refetch,
    isFetching
  } = useQuery({
    queryKey: ['random-image-generator'],
    queryFn: () => fetchRandomImageKeys()
  });
  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const generateRandomImage = async () => {
    setSelectedImage(null);
    if (randomImgIdx === 4) {
      // fetch and then the rest
      const data = await refetch();
      setPreviewUrl(`${LEAGUES_IMAGE_PREFIX}/${data.data[0]}`);
      setRandomImgIdx(1);
      return;
    }
    setPreviewUrl(`${LEAGUES_IMAGE_PREFIX}/${randomImages[randomImgIdx]}`);
    setRandomImgIdx(randomImgIdx + 1);
  };
  const handleImageSelect = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Validate file type
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    const maxSizeMB = 1;
    if (!allowedTypes.includes(file.type)) {
      failed({
        error: 'Invalid file type. Please select an image.'
      });
      return;
    }

    // Validate file size
    if (file.size > maxSizeMB * 1024 * 1024) {
      failed({
        error: `File size must be less than ${maxSizeMB}MB`
      });
      return;
    }

    // Create preview URL
    const url = URL.createObjectURL(file);
    setPreviewUrl(url);
    setSelectedImage(file);

    // Clean up old preview URL if it exists
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
  };
  const handleUpload = async (): Promise<void> => {
    if (!userProfileData) return;
    if (!address) return;
    try {
      setProcessing(true);
      const nonce = await fetchUserProfileNonce({
        address
      });
      if (selectedImage) {
        const messageToSign = `Update Profile:\nWallet Address: ${address}\nUsername: ${userProfileData.username}\nProfile Picture: ${userProfileData.profileImageKey}\nNonce: ${nonce}\n\nBy signing this message, you authorize the update of your profile details.`;
        const signature = await signMessage(config, {
          message: messageToSign
        });
        await updateUserProfileImage({
          address,
          username: userProfileData.username,
          imageKey: userProfileData.profileImageKey,
          signature,
          selectedImage
        });
        setProcessing(false);
        success({
          message: 'Your profile image has been updated!',
          heading: 'Profile Image updated'
        });
      } else if (!userProfileData.isSet) {
        const response = await fetch(`${LEAGUES_IMAGE_PREFIX}/${userProfileData.profileImageKey}`);
        const blob = await response.blob();
        const file = new File([blob], randomImages[randomImgIdx], {
          type: blob.type
        });
        const messageToSign = `Update Profile:\nWallet Address: ${address}\nUsername: ${userProfileData.username}\nProfile Picture: ${userProfileData.profileImageKey}\nNonce: ${nonce}\n\nBy signing this message, you authorize the update of your profile details.`;
        const signature = await signMessage(config, {
          message: messageToSign
        });
        await updateUserProfileImage({
          address,
          username: userProfileData.username,
          imageKey: userProfileData.profileImageKey,
          signature,
          selectedImage: file
        });
        setProcessing(false);
        success({
          message: 'Your profile image has been updated!',
          heading: 'Profile Image updated'
        });
      } else {
        const messageToSign = `Update Profile:\nWallet Address: ${address}\nUsername: ${userProfileData.username}\nProfile Picture: ${randomImages[randomImgIdx - 1]}\nNonce: ${nonce}\n\nBy signing this message, you authorize the update of your profile details.`;
        const signature = await signMessage(config, {
          message: messageToSign
        });
        await updateUserProfile({
          address,
          username: userProfileData.username,
          imageKey: randomImages[randomImgIdx - 1],
          signature
        });
        setProcessing(false);
      }
      refetchLeaguesUserInfo();
      setOpenModal(false);
      refetchUserProfile();
      onSuccess();
    } catch (error) {
      setProcessing(false);
      const e = serializeError(error);
      failed({
        error: e.message
      });
    }
  };
  const profileImage = useMemo(() => {
    if (previewUrl) {
      return previewUrl;
    }
    if (userProfileData) {
      return `${LEAGUES_IMAGE_PREFIX}/${userProfileData.profileImageKey}`;
    }
    return '';
  }, [previewUrl, userProfileData]);
  return <ResponsiveDialog heading={<p className="text-grey-000 text-lg font-medium">Change Avatar</p>} open={openModal} className="max-w-[480px]" onCleanup={() => {
    setOpenModal(false);
  }} footer={<div className="flex gap-2">
          <Button className="w-full text-base font-normal" variant="collateral" onClick={() => {
      setOpenModal(false);
    }}>
            Cancel
          </Button>
          <Button className="w-full" variant="long" disabled={!checked || processing || userProfileData && userProfileData.isSet && !previewUrl} onClick={() => handleUpload()}>
            {processing ? <Spinner /> : <p>Save</p>}
          </Button>
        </div>} data-sentry-element="ResponsiveDialog" data-sentry-component="EditProfileImage" data-sentry-source-file="EditProfileImage.tsx">
      <div className="w-full px-8 py-6 flex flex-col items-center gap-6">
        {profileImage && !isFetching ? <Avatar className="w-32 h-32 overflow-hidden">
            <AvatarImage src={profileImage} alt="profile avatar" className="object-cover" />
            <AvatarFallback>
              <Skeleton className="h-32 w-32 rounded-full" />
            </AvatarFallback>
          </Avatar> : <Skeleton className="h-32 w-32 rounded-full" />}
        <div className="flex items-center gap-8">
          <input type="file" ref={fileInputRef} onChange={handleImageSelect} className="hidden" aria-label="File input" />

          <p role="button" className="text-base font-normal text-primary-100" onClick={triggerFileInput}>
            Upload Image
          </p>
          <p role="button" className="text-base font-normal text-primary-100" onClick={generateRandomImage}>
            Generate Random
          </p>
        </div>

        <div className="flex items-center gap-4">
          <Checkbox color="#fff" className="h-5 w-5 " checked={checked} onCheckedChange={() => {
          setChecked(!checked);
        }} data-sentry-element="Checkbox" data-sentry-source-file="EditProfileImage.tsx" data-sentry-element="Checkbox" />
          <p className="text-grey-100 text-sm font-normal">
            I agree not to upload any offensive, explicit, or inappropriate content, and any
            violation may result in suspension.
          </p>
        </div>
      </div>
    </ResponsiveDialog>;
};
export default EditProfileImage;