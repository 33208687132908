import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Address, encodeFunctionData, keccak256, pad, sha256, toHex } from 'viem';
import { useAccount, usePublicClient } from 'wagmi';
import { requestApp } from '@/lib/api';
import { useOneCTStore } from '@/store/oneCT';
import { arrayBufferToBase64, decryptSymmetric, encryptSymmetric } from '@/lib/oneCT';
import { privateKeyToAddress } from 'viem/accounts';
import { signMessage } from '@wagmi/core';
import { config } from '@/blockchain/configs/wagmi';
import { contractsConfig } from '@/blockchain/contracts';
import BigNumber from 'bignumber.js';
import useExecutionFee from '../trade/useExecutionFee';
import { noOp } from '@/constants/common';
import useGasFees from './useGasFees';
import { safeParseUnits } from '@/lib/numbers';

interface IOneCTWalletRes {
  user: {
    userAddress: Address;
    oneClickWallets: {
      value: Address;
    }[];
  };
  success: boolean;
}

const useOneCT = () => {
  const { address } = useAccount();
  const { updateOneCTStore, oneCTStorage, enableOneCT } = useOneCTStore((state) => state);
  const client = usePublicClient();
  const executionFee = useExecutionFee();
  const { maxFeePerGas, maxPriorityFeePerGas } = useGasFees();

  const { data: gasPerTradeEst, isLoading: ethPerTradeEstLoading } = useQuery({
    queryKey: [
      'gas-per-trade-est',
      executionFee,
      maxFeePerGas.toString(),
      maxPriorityFeePerGas.toString(),
    ],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      // storage slot calculated just for this address
      const trader = '0xAC5092A5c7302693a8e39643339109d21DBad723';
      const updatedTradeData = {
        buy: true,
        index: 0n,
        initialPosToken: 0n,
        leverage: 200000000000n,
        liqPrice: 23600400000000n,
        openPrice: 24647900000000n,
        pairIndex: 0n,
        positionSizeUSDC: 10000000n,
        sl: 0n,
        timestamp: 1715212291n,
        tp: 30809800000000n,
        trader,
      };

      const callData = encodeFunctionData({
        functionName: 'openTrade',
        abi: contractsConfig.Trading.abi,
        args: [updatedTradeData, 0, 30000000000n, 0],
      });

      const gasPrice = maxFeePerGas + maxPriorityFeePerGas;

      const amount = safeParseUnits('10', 6);

      let contractCallGas = 0n;
      try {
        contractCallGas = await client.estimateGas({
          account: trader,
          data: callData,
          to: contractsConfig.Trading.address,
          stateOverride: [
            {
              address: contractsConfig.USDC.address,
              stateDiff: [
                {
                  slot: '0xc433212f7120549c4c8ca8b281760eec89eb2f977f12b30c0f34f7759a2bcc27',
                  value: pad(toHex(amount), { size: 32 }),
                },
                {
                  slot: '0xbcc24fb1fca6be4a5aa576910365ce0cd2e7cf775e9d060a40ca4612273fd653',
                  value: pad(toHex(amount), { size: 32 }),
                },
              ],
            },
          ],
        });
      } catch (e) {
        console.log(e);
      }

      const gas = BigNumber(contractCallGas.toString())
        .multipliedBy(gasPrice.toString())
        .multipliedBy(2);
      const keeperETHFee = BigNumber(executionFee).multipliedBy(1e18);
      return {
        ethPerTradeEst: gas.plus(keeperETHFee).dividedBy(1e18).toFixed(6),
        gasPerTradeEst: (contractCallGas * 2n).toString(),
      };
    },
  });

  const { data: walletList } = useQuery({
    queryKey: [address, 'one-ct-addresses'],
    queryFn: async () => {
      const data = await requestApp<IOneCTWalletRes>({
        endpoint: `/onect/${address}`,
        version: 'v2',
      });

      let wallets: {
        address: Address;
        isEnabled: boolean;
      }[] = [];

      if (data.success && data.user) {
        wallets = data.user.oneClickWallets.map((wallet) => {
          return { address: wallet.value, isEnabled: false };
        });
      }

      return wallets;
    },
    enabled: !!address,
  });

  const { data: oneCTWallets, isLoading: oneCTWalletsLoading } = useQuery<
    { isEnabled: boolean; address: Address }[]
  >({
    queryKey: [address, walletList, JSON.stringify(oneCTStorage), 'one-ct-wallets'],
    placeholderData: keepPreviousData,
    staleTime: 0,
    queryFn: async () => {
      let updatedWalletList = walletList ? walletList : [];

      if (walletList) {
        for (const wallet of walletList) {
          const current1CT = oneCTStorage[wallet.address];
          if (!current1CT) {
            const index = walletList.findIndex((oneCT) => oneCT.address === wallet.address);
            if (index != -1) {
              updatedWalletList[index].isEnabled = false;
            }
            continue;
          }
          if (current1CT.enabled) {
            const decryptedSignature = (await decryptSymmetric(
              current1CT.ciphertext,
              current1CT.iv,
              current1CT.base64Key
            )) as Address;
            const signedUint8 = new TextEncoder().encode(decryptedSignature);
            const oneCTPrivateKey = keccak256(signedUint8);
            const oneCTPublicKey = privateKeyToAddress(oneCTPrivateKey);

            updateOneCTStore('privateKey', oneCTPrivateKey);
            updateOneCTStore('publicKey', oneCTPublicKey);

            const index = walletList?.findIndex((oneCT) => oneCT.address === wallet.address);
            if (index != -1) {
              updatedWalletList[index].isEnabled = true;
            }
          } else {
            const index = walletList.findIndex((oneCT) => oneCT.address === wallet.address);
            if (index != -1) {
              updatedWalletList[index].isEnabled = false;
            }
          }
        }

        return updatedWalletList;
      } else {
        return [];
      }
    },
  });

  const handlePinEntered = async ({
    pin,
    isOnboarding = false,
    onSuccessCallback = noOp,
    onFailureCallback = noOp,
  }: {
    pin: string;
    isOnboarding?: boolean;
    onSuccessCallback?: () => void;
    onFailureCallback?: () => void;
  }) => {
    // get sha256 hash of address + pin
    const combined = String(address) + String(pin);
    const msgUint8 = new TextEncoder().encode(combined);
    const hash = sha256(msgUint8);

    // get this hash signed by wallet
    const signed = await signMessage(config, { message: hash });

    const signedUint8 = new TextEncoder().encode(signed);
    const privateKey = keccak256(signedUint8);
    const publicKey = privateKeyToAddress(privateKey);

    const key = await window.crypto.subtle.generateKey(
      {
        name: 'AES-GCM',
        length: 256,
      },
      true,
      ['encrypt', 'decrypt']
    );

    // encryption
    const rawKey = await crypto.subtle.exportKey('raw', key);
    const base64Key = arrayBufferToBase64(rawKey);
    const { iv, ciphertext } = await encryptSymmetric(signed, base64Key);

    if (isOnboarding) {
      enableOneCT({
        oneCTOwner: address as Address,
        iv,
        ciphertext,
        base64Key,
        publicKey,
        privateKey,
      });
    }

    const isValid = oneCTWallets?.reduce((acc, wallet) => {
      if (wallet.address === publicKey) {
        return true;
      }
      return acc;
    }, false);

    if (!isValid) {
      updateOneCTStore('pinError', true);
      onFailureCallback();
      return { publicKey, privateKey };
    }

    enableOneCT({
      oneCTOwner: address as Address,
      iv,
      ciphertext,
      base64Key,
      publicKey,
      privateKey,
    });

    onSuccessCallback();

    return { publicKey, privateKey };
  };

  return {
    oneCTWallets: oneCTWallets ?? [],
    handlePinEntered,
    gasPerTradeEst,
    ethPerTradeEstLoading,
    oneCTWalletsLoading,
  };
};

export default useOneCT;
